<template>
  <div>
    <dateHours
      v-for="(d, index) in dates"
      :style="'margin-top: ' + getMarginTop(index) + 'px'"
      :key="index"
      :weekday="weekday"
      :dates="d"
    />
  </div>
</template>
<script>
export default {
  components: {
    dateHours() {
      return import("./hours.vue");
    }
  },
  props: {
    weekday: [Number, String]
  },
  data() {
    return {
      dates: this.getDates()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getMarginTop(index) {
      var weekday = this.weekday * 1;
      var nextAppointments = this.$store.state.business.unsaved
        .next_appointments[weekday];
      var buffer = this.$store.state.business.unsaved
        .include.buffer[weekday];

      nextAppointments = nextAppointments * 1 + buffer * 1

      return 68 * (nextAppointments / 15) * index;
    },
    getDates() {
      var weekday = this.weekday * 1;
      var includeWeekday = this.$store.state.business.unsaved.include.weekdays[
        weekday
      ];
      var excludeWeekday = false;
      if (
        typeof this.$store.state.business.unsaved.exclude.weekdays[weekday] !==
        "undefined"
      ) {
        excludeWeekday = this.$store.state.business.unsaved.exclude.weekdays[
          weekday
        ];
      }
      var nextAppointments = this.$store.state.business.unsaved
        .next_appointments[weekday];

      var buffer = this.$store.state.business.unsaved
        .include.buffer[weekday];

      nextAppointments = nextAppointments * 1 + buffer * 1

      if (!includeWeekday[0]) {
        return false;
      }
      var r = [];
      var start = new Date();
      start.setHours(includeWeekday[0].split(":")[0]);
      start.setMinutes(includeWeekday[0].split(":")[1]);
      start.setSeconds(0);
      start.setMilliseconds(0);

      var end = new Date();
      end.setHours(includeWeekday[1].split(":")[0]);
      end.setMinutes(includeWeekday[1].split(":")[1]);
      end.setSeconds(0);
      end.setMilliseconds(0);

      var steps = (end.getTime() - start.getTime()) / 1000 / 60;
      end = new Date(start);
      end.setMinutes(end.getMinutes() + includeWeekday[2] * 1);

      var check = includeWeekday[2] / nextAppointments;
      if (check < 1) {
        check = 1;
      }
      var count = 1;

      // window.console.log(includeWeekday[2], nextAppointments, check)
      for (var i = 0; i < steps; i += nextAppointments * 1) {
        if (typeof r[count - 1] === "undefined") {
          r[count - 1] = [];
        }
        r[count - 1].push([
          this.getNullsBefore(start.getHours()) +
            ":" +
            this.getNullsBefore(start.getMinutes()),
          this.getNullsBefore(end.getHours()) +
            ":" +
            this.getNullsBefore(end.getMinutes()),
          this.checkPause(excludeWeekday, start, end)
        ]);
        start.setMinutes(start.getMinutes() + nextAppointments * 1);
        end.setMinutes(end.getMinutes() + nextAppointments * 1);
        count++;
        if (count === check + 1) {
          count = 1;
        }
      }

      return r;
    },
    checkPause(weekday, checkStart, checkEnd) {
      if (!weekday) {
        return true;
      }

      var success = true;
      weekday.forEach(function(pause) {
        if (!success) {
          return success;
        }
        var start = new Date();
        start.setHours(pause[0].split(":")[0]);
        start.setMinutes(pause[0].split(":")[1] * 1 + 1);
        start.setSeconds(0);
        start.setMilliseconds(0);
        var end = new Date();
        end.setHours(pause[1].split(":")[0]);
        end.setMinutes(pause[1].split(":")[1] * 1 - 1);
        end.setSeconds(0);
        end.setMilliseconds(0);

        if (
          checkStart.getTime() <= end.getTime() &&
          checkEnd.getTime() >= start.getTime()
        ) {
          success = false;
        }
      });

      return success;
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    }
  }
};
</script>
<style lang="sass" scoped></style>
